/*
 * File: Config.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import {
  PathConfigType,
  BackEndConfigType,
  ConfigCommonType,
  ConfigType,
  CookieConfigType,
} from "Config/Config.type";

/**
 * The enum of the modes
 */
const ENUM_MODE = {
  DEV: "dev",
  PROD: "prod",
  STAGING: "staging",
};

/**
 * Config shared between the different modes
 */
const Common: ConfigCommonType = {
  LANGUAGES: ["fr", "en", "es"],
  CONTACTEMAIL: "contact@mobyfleet.fr",
  COMPANY_NAME: "MOBYFLEET"
};

const Paths: PathConfigType = {
  LOGIN: "/auth",
  REFRESH: "/refresh",
  GET_USER: "/users",
  ADD_USER: "/users/create",
  CAR: "/car",
  GET_PICTURE: "/car/pictures/",
  GET_CAR_CATEGORIES: "/car/categories",
  GET_CAR_ENERGIES: "/car/energies",
  GET_CAR_BRANDS: "/car/brands",
  GET_CAR_MODELS: "/car/models",
  GET_CAR_EQUIPMENT: "/car/equipment/",
  GET_CATEGORY_COUNT: "/car/count",
  GET_BRAND_MOST_COUNT: "/car/brand",
  GET_CARS_BY_BRAND: "/car/brand/",
  POST_REQUEST_CONTACT: "/contact",
  GET_TEXT: "/text/",
  PROFILE: "/profile",
  PRINCING: "/pricing",
  ATTRIBUED_CAR: "/attribuedcar",
  OFFERFILES: "/offerfiles",
  SUMMARY: "/info",
  FAVORITES: "/favorites",
  OFFERS: "/offers",
};

const DevBack: BackEndConfigType = {
  BASEURL: "http://localhost:6789/api",
  PATHS: Paths,
};

const DevCookie: CookieConfigType = {
  COOKIE_TIME_DAYS: 0.5,
  COOKIE_TIME_REFRESH_DAYS: 90,
};

const ProdBack: BackEndConfigType = {
  BASEURL: "https://www.back.autofi.fr/api",
  PATHS: Paths,
};

const ProdCookie: CookieConfigType = {
  COOKIE_TIME_DAYS: 0.5,
  COOKIE_TIME_REFRESH_DAYS: 90,
};

const StagingBack: BackEndConfigType = {
  BASEURL: "https://www.testing-api.mobyfleet.fr/api",
  PATHS: Paths,
};

const StagingCookie: CookieConfigType = {
  COOKIE_TIME_DAYS: 0.5,
  COOKIE_TIME_REFRESH_DAYS: 90,
};

/**
 * The internal config for the dev mode
 */
const Dev: ConfigType = {
  COMMON: Common,
  BACKEND: DevBack,
  COOKIE: DevCookie,
  MODE: ENUM_MODE.DEV,
};

/**
 * The internal config for production mode
 */
const Prod: ConfigType = {
  COMMON: Common,
  BACKEND: ProdBack,
  COOKIE: ProdCookie,
  MODE: ENUM_MODE.PROD,
};

/**
 * The internal config for staging mode
 */
const Staging: ConfigType = {
  COMMON: Common,
  BACKEND: StagingBack,
  COOKIE: StagingCookie,
  MODE: ENUM_MODE.STAGING,
};

let Config: ConfigType;

if (process.env.REACT_APP_MODE === ENUM_MODE.PROD) Config = Prod;
else if (process.env.REACT_APP_MODE === ENUM_MODE.DEV) Config = Dev;
else if (process.env.REACT_APP_MODE === ENUM_MODE.STAGING) Config = Staging;
else throw Error("Invalid mode: " + process.env.REACT_APP_MODE);

export default Config;
