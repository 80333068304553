/*
 * File: Footer.tsx
 * Project: front
 * File Created: Thursday, 12th January 2023 02:24:29
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 6th November 2023 11:04:16
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import styles from "Content/Footer/Footer.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import FooterLiStyled from "Theme/Component/FooterLiStyled";
import { NavLink } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import SocialMedia from "Theme/Component/SocialMedia";
import RouterPathResolver from "Config/Router/RouterPathResolver";

type NavlinkStyleActiveType = {
    isActive: boolean,
    isPending: boolean
};

function Footer (props: {themeColor: string}) {
    const FooterList = [
        {
            name:"legal",
            href:RouterPathResolver.routeCgv.path
        },
        {
            name:"contact",
            href:RouterPathResolver.routeContact.path
        },
        {
            name:"data_protection",
            href:RouterPathResolver.routeRgpd.path
        },
        {
            name:"faq",
            href:RouterPathResolver.routeFinanceFaq.path
        }
    ];

    const socialMediaList = [
        {
            name: "instagram",
            href: "https://www.instagram.com/mobyfleet/",
            icon: <FaInstagram/>,
            color:"#7232BD"
        },
        {
            name: "facebook",
            href: "https://www.facebook.com/profile.php?id=100090689510017",
            icon: <FaFacebookF/>,
            color:"#3b5998"
        },
        {
            name: "linkedin",
            href: "https://www.linkedin.com/company/autofi-fr/",
            icon: <FaLinkedinIn/>,
            color:"#007BB5"
        },
        {
            name: "whatsapp",
            href: "https://wa.me/33757689661",
            icon: <FaWhatsapp/>,
            color:"#25D366"
        },
    ];

    const activeStyle = {
        scale:"1 !important",
        padding:"0",
        letterSpacing:"0"
    };
    return (
        <div className={`${styles.footer}`}>
            <ul className={`${styles.footer_ul}`}>
                {
                    FooterList.map((item: {name: string, href: string}, index: number)=> {
                        return <FooterLiStyled key={item.name + index} bgColor={props.themeColor}>
                                    <NavLink to={item.href} style={(activeParam: NavlinkStyleActiveType) => ( activeParam.isActive ? activeStyle : {} )}>
                                        {translator.decode(`footer.${item.name}`).toUpperCase()}
                                    </NavLink>
                                </FooterLiStyled>
                    })
                }
            </ul>
            {/* <div className={`${styles.socialMedia}`}>
                {
                    socialMediaList.map((item: {name: string, href: string, icon: JSX.Element, color: string}, index: number)=>{
                        return  <SocialMedia aria-label={`Open ${item.name}`} key={`${item}${index}`} href={item.href} target="_blank" bgColor={item.color}>
                                    {item.icon}
                                </SocialMedia>
                    })
                }
            </div> */}
            <p>{translator.decode("footer.copyright")}</p>
        </div>
    );
}

export default Footer;
