/*
 * File: AdressService.ts
 * Project: autofi-front
 * File Created: Thursday, 2nd March 2023 05:12:55
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd March 2023 03:52:15
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */
import { buildQueryString } from "build-url-ts";
import { OptionType } from "Content/Contact/ContactSelect/OptionTypeInterface";

type QueryParams = { [key: string]: string | string[] };

export const getAddresses = async (input: string) => {
  const addr: QueryParams = { limit: "10" };

  addr["text"] = input;
  addr["format"] = "json";
  addr["apiKey"] = "68e91e0d518a45269b903042c76dada0";
  const query = buildQueryString(addr);
  const uri = "https://api.geoapify.com/v1/geocode/autocomplete" + query;
  const url = uri;
  const tmp = await fetch(url);
  const result = await tmp.json();
  const propositions: OptionType[] = [];
  if (result["results"] !== undefined) {
    for (const res of result["results"]) {
      if (res !== undefined)
        propositions.push({
          label: res["formatted"],
          value: res["formatted"],
          data: {
            houseNumber: res["housenumber"],
            street: res["street"],
            postalCode: res["postcode"],
            city: res["city"],
            state: res["state"],
            country: res["country"],
            score: res["score"],
          },
        });
    }
  }
  return propositions;
};
