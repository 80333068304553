/*
 * File: CarList.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 04:45:24
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd February 2023 09:16:18
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useState } from "react";
import CarsDisplay from "Content/Car/CarsDisplay/CarsDisplay";
import CarService from "Services/Cars/CarService";
import { CarCardPropsType } from "../CarCard/CarCard.type";
import { CarListable } from "./CarList.type";

type CarListProps = {
    category: string
}

export function CarList(props: CarListProps) {
    const [carList, setCarList] = useState<CarListable[]>([]);

    useEffect(() => {
        CarService.getCarsByCategory(props.category).then(result => {
            setCarList(result?.cars);
        }).catch(error => {
            console.error(error);
        })
    }, []);

    return (
        <CarsDisplay carList={carList} themeColor="" />
    );
}

export default CarList;
