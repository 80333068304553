/*
 * File: Router.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */

import { createBrowserRouter } from "react-router-dom";
import Root from "Content/Root/Root";
import ErrorPage from "Content/Error/ErrorPage/ErrorPage";
import { Contact } from "Content/Contact/Contact";
import CarService from "Services/Cars/CarService";
import CarElement from "Content/Car/CarElement/CarElement";
import CGV from "Content/CGV/CGV";
import HomeCardHolder from "Content/Home/HomeCardHolder/HomeCardHolder";
import AboutUsHolder from "Content/AboutUs/AboutUsHolder";
import Financement from "Content/Financement/Financement";
import FaqFinancement from "Content/Faq/Faq_financement";
import Login from "Content/Login/Login";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import RGPD from "Content/RGPD/RGPD";
import { InterractiveHeaderProvider } from "./HeaderInteractionProvider";
import CreateAccount from "Content/CreateAccount/CreateAccount";
import ServiceGeneral from "Services/ServiceGeneral";
import { AuthProvider } from "./AuthProvider";
import ProfilePage from "Content/ProfilePage/ProfilePage";
import UserService from "Services/Users/UserService";
import PasswordRenewal from "Content/Password/PasswordRenewal/PasswordRenewal";
import PasswordForgotten from "Content/Password/PasswordForgotten/PasswordForgotten";
import VerifyUser from "Content/CreateAccount/VerifyUser/VerifyUser";
import CarTabs from "Content/Car/CarTabs/CarTabs";
import { ExtractQueryInObj } from "Services/RouterService/RouterService";
import OfferTabs from "Content/Cart/OfferTabs/offerTabs";

export const Router = createBrowserRouter([
  {
    path: `${RouterPathResolver.pathEntryPoint.path}`,
    element: <AuthProvider><InterractiveHeaderProvider><Root children={[]} /></InterractiveHeaderProvider></AuthProvider>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: `${RouterPathResolver.routeContact.path}`,
        element: <Contact Category={null} Energy={null} Brand={null} Model={null} CarId={null} CarTitle={null} Mileage={undefined} />,
      },
      {
        path: `${RouterPathResolver.pathEntryPoint.path}`,
        element: <HomeCardHolder />
      },
      {
        path: `${RouterPathResolver.routeCars.path}`,
        loader:({request}) => {
          return ExtractQueryInObj(request);
        },
        element: <CarTabs />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RouterPathResolver.routeCarsId.path}`,
        loader: ({params}) => {
          return CarService.getSingleCar(params.id!);
        },
        element: <CarElement />,
        errorElement: <ErrorPage />,
      },
      {
        path: `${RouterPathResolver.routeCgv.path}`,
        element:<CGV />
      },
      {
        path: `${RouterPathResolver.routeRgpd.path}`,
        element:<RGPD />
      },
      {
        path: `${RouterPathResolver.routeVerifyUser.path}`,
        loader: async ({params}) => {
          const myRet = await ServiceGeneral.manageAxiosResponse(() => {return UserService.verify(params.token!)});
          return myRet;
        },
        element: <VerifyUser />,
      },
      {
        path: `${RouterPathResolver.routeSignIn.path}`,
        element: <CreateAccount />,
      },
      {
        path: `${RouterPathResolver.routeLogin.path}`,
        element:<Login />
      },
      {
        path: `${RouterPathResolver.routeNewPassword.path}`,
        loader: async ({params}) => {
          return params.token;
        },
        element: <PasswordRenewal />,
      },
      {
        path: `${RouterPathResolver.routeForgotPassword.path}`,
        element: <PasswordForgotten />,
      },
      {
        path: `${RouterPathResolver.routeProfil.path}`,
        loader: async ({}) => {
          const myProfile = await ServiceGeneral.manageAxiosResponse(() => {return UserService.getMyProfile()});
          return myProfile;
        },
        element: <ProfilePage />,
      },
      {
        path: `${RouterPathResolver.routeFinanceFaq.path}`,
        element:<FaqFinancement />
      },
      {
        path: `${RouterPathResolver.routeFinance.path}`,
        element:<Financement />
      },
      {
        path: `${RouterPathResolver.routeAbout.path}`,
        element:<AboutUsHolder />
      },
      {
        path: `${RouterPathResolver.routeCart.path}`,
        loader: ({request}) => {
          return ExtractQueryInObj(request);
        },
        element:<OfferTabs />
      }
    ]
  },
]);
