/*
 * File: Root.tsx
 * Project: autofi-front
 * File Created: Thursday, 12th January 2023 02:31:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 23rd February 2023 09:16:50
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */
import {Dispatch, SetStateAction} from "react";
import { Outlet, useOutletContext } from 'react-router-dom';
import styles from 'Content/Main/Main.module.css';
import ToTopButton from 'Content/ToTopButton/ToTopButton';
import ScrollToTop from 'Content/ScrollToTop/ScrollToTop';

type ContextType = {
    setterThemeColor: Dispatch<SetStateAction<string>>;
};
type MainPropsType = {
    themeColor: string;
    changeThemeColor: Dispatch<SetStateAction<string>>;
};

function Main(props: MainPropsType) {
    return(
        <div className={`${styles.view}`}>
            <Outlet context={{ setterThemeColor: props.changeThemeColor}}/>
            <ToTopButton themeColor={props.themeColor}/>
            <ScrollToTop/>
        </div>
    );
};

export function useMyContext(){
    return useOutletContext<ContextType>();
};

export default Main;
